export type Room = {
    title: string;
    image: string;
    url: string;
    price: number;
};

export const rooms: { [key: string]: Room} = {
    "standard2": {
        title: '2-lôžková izba Štandard',
        image: 'izby/izba-standard-2lozkova.jpg',
        url: '2-lozkova-izba-standard',
        price: 60,
    },
    "standard3": {
        title: '3-lôžková izba Štandard',
        image: 'izby/izba-standard-2lozkova.jpg',
        url: '3-lozkova-izba-standard',
        price: 90,
    },
    "premium": {
        title: 'Izba Premium',
        image: 'izby/izba-premium.jpg',
        url: '3-lozkova-izba-standard',
        price: 100,
    },
    "family": {
        title: 'Rodinný apartmán',
        image: 'izby/apartman-rodinny.jpg',
        url: 'rodinny-apartman',
        price: 120,
    },
    "komfort": {
        title: 'Apartmán Komfort',
        image: 'izby/apartman-komfort.jpg',
        url: 'apartman-komfort',
        price: 120,
    },
    "bezbarier": {
        title: 'Bezbarierová izba Premium',
        image: 'izby/izba-premium-3.jpg',
        url: 'izba-premium',
        price: 100,
    },
};
